import http from "../../helpers/http-common";

class InstructorAvailabilityDataService {
    getAll() {
        return http.get("/instructor_availabilities");
    }

    getAllByDate(dateStart, dateEnd) {
        return http.get(`/instructor_availabilities/by-date/${dateStart}/${dateEnd}`);
    }

    getIncludeByDate(dateStart, dateEnd) {
        return http.get(`/instructor_availabilities/include-by-date/${dateStart}/${dateEnd}`);
    }

    getAllByInstructor(id, dateStart = null, dateEnd = null) {
        return http.get(`/instructor_availabilities/instructor/${id}/${dateStart}/${dateEnd}`);
    }

    get(id) {
        return http.get(`/instructor_availabilities/${id}`);
    }

    create(data) {
        return http.post("/instructor_availabilities", data);
    }

    update(id, data) {
        return http.put(`/instructor_availabilities/${id}`, data);
    }

    delete(id) {
        return http.delete(`/instructor_availabilities/${id}`);
    }

    deleteAll() {
        return http.delete('/instructor_availabilities');
    }

    findByName(name) {
        return http.get(`/instructor_availabilities?name=${name}`);
    }
}

export default new InstructorAvailabilityDataService();
